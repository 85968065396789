import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "../src/Css/Style.css";
import "../src/Css/Responsive.css";
import Call from "./Components/Call-rep";
import Optin from "./Components/Optin";
import Masterclass from "./Components/Masterclass";
import Thankyou from "./Components/Thankyou";
import Apply from "./Components/Apply";
import MC from "./Components/MC";
import A from "./Components/A";
import "react-toastify/dist/ReactToastify.css";
import Freewebinar from "./Components/Freewebinar";
import MakeAdvance from "./Components/MakeAdvance";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route exact path="/" element={<Optin />} />
        <Route exact path="/Masterclass" element={<Masterclass />} />
        <Route exact path="/Apply" element={<Apply />} />
        <Route exact path="/Thankyou" element={<Thankyou />} />
        {/* <Route exact path="/Call" element={<Call />} />
        <Route exact path="/MC" element={<MC />} />
        <Route exact path="/A" element={<A />} />
        <Route exact path="/Freewebinar" element={<Freewebinar />} />
        <Route exact path="/MakeAdvance" element={<MakeAdvance />} /> */}
        
      </Routes>
    </HashRouter>
  );
}

export default App;
