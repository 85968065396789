import React, { useEffect } from "react";
import logo from "../Assets/Logo/logo.webp";
import ReactPlayer from "react-player/youtube";

const Call = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container">
        <section className="mt-5">
          <div className="alignment">
            <img src={logo} alt="Naturals" className="logo" />
            <p className="thankstitle mt-5">
              YOUR CALL HAS BEEN BOOKED & SENT VIA EMAIL
            </p>
            <p className="thankstitle">
              Important: Please Watch the Video Below Before Our Call ⤸
            </p>
            <div className="alignment">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                className="videoalignment"
                controls={true}
              />
            </div>
          </div>
        </section>
        <section className="mt-5">
          <div className="row">
            <div className="col-lg-6 mx-auto ms-auto">
              <p className="para">
                👉🏼 Please confirm our meeting time works for you.
                <br />
                👉🏼 Add the meeting to your calendar (so you don't forget)!
                <br />
                👉🏼 Let us know ASAP if you need to change the time.
              </p>
            </div>
          </div>
          <div className="alignment mt-5">
            <p className="thankstitle">
              Still not sure if Naturals Academy will work for you?
            </p>
            <p className="thankssubtitle">
              Check out some of the feedback from our amazing community!
            </p>
            <div className="alignment">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                className="videoalignment"
                controls={true}
              />
            </div>
            <p className="title mt-5">
              More Student Case Studies & Success Stories
            </p>
          </div>
        </section>
        <section>
          <div className="row mt-4">
            <div className="col-lg-6">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                className="videoalignmentcall"
                controls={true}
              />
            </div>
            <div className="col-lg-6">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                className="videoalignmentcall"
                controls={true}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-6">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                className="videoalignmentcall"
                controls={true}
              />
            </div>
            <div className="col-lg-6">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                className="videoalignmentcall"
                controls={true}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-6">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                className="videoalignmentcall"
                controls={true}
              />
            </div>
            <div className="col-lg-6">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                className="videoalignmentcall"
                controls={true}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Call;
